<template>
  <div class="print-s">
  <template v-if="outletOrderType">
    
    <!-- <div v-if="tableData.length < 6" style="padding-top:50px;"></div> -->
    <div style="padding-top:30px;"></div>

    <div class="t-hd flex">
      <div><span class="mr-4">(v{{CONFIG.VERSION}})</span>打印时间：{{serverTime}}</div>
      <!-- <div v-if="outletOrderType == 1">自提: 取餐码 {{data.pick_up_info.pick_up_code}}</div> -->
      <div v-if="outletOrderType == 1">自提</div>
      <div v-if="outletOrderType == 2">配送</div>
    </div>

    <a-descriptions bordered size="middle" class="descri-class" :column="24">
      <!-- 配送信息 -->
      <template v-if="outletOrderType == 2">
        <!-- 1 -->
        <a-descriptions-item :span="8" label="收货人">{{ outletOrder.delivery_info.consignee_name }}</a-descriptions-item>
        <template>
          <a-descriptions-item :span="7" v-if="bookInfo.isAdminOrder" label="下单人">{{ bookInfo.name }}</a-descriptions-item>
          <a-descriptions-item :span="7" v-else label="联系人">{{ contactInfo && contactInfo.name }}</a-descriptions-item>
        </template>
        <a-descriptions-item :span="9" label="订单ID">{{ data.order_no }}</a-descriptions-item>
        <!-- 2 -->
        <a-descriptions-item :span="8" label="收货电话">{{ outletOrder.delivery_info.consignee_phone }}</a-descriptions-item>
        <template>
          <a-descriptions-item :span="7" v-if="bookInfo.isAdminOrder" label="下单电话">{{ bookInfo.phone }}</a-descriptions-item>
          <a-descriptions-item :span="7" v-else label="联系电话">{{ contactInfo && contactInfo.phone }}</a-descriptions-item>
        </template>
        <a-descriptions-item :span="9" label="下单时间">{{ data.add_time }}</a-descriptions-item>
        <!-- 3 -->
        <a-descriptions-item :span="8" label="收货时间">{{ getGoodsTime() }}</a-descriptions-item>
        <a-descriptions-item :span="7" label="订单金额">
          <template v-if="Number(data.freight_amount) > 0">
            {{ data.order_amount }} 元 (含运费{{ data.freight_amount }})
          </template>
          <template v-else>
            {{ data.order_amount }} 元
          </template>
        </a-descriptions-item>
        <a-descriptions-item :span="9" label="支付方式">
          <template v-if="data.order_source >= 20">{{ formatOrderSource(data.order_source) }} - </template>
          <template>{{ getPayTypeShowName() }}</template>
        </a-descriptions-item>

        <!-- 4 收货地址 -->
        <a-descriptions-item :span="24" label="收货地址">{{outletOrder.delivery_info.consignee_address}}</a-descriptions-item>
      </template>

      <!-- 自提信息 -->
      <template v-if="outletOrderType == 1">
        <a-descriptions-item :span="9" label="取货人">{{ outletOrder.pick_up_info.recipient_name }}</a-descriptions-item>
        <template>
          <a-descriptions-item :span="6" v-if="bookInfo.isAdminOrder" label="下单人">{{ bookInfo.name }}</a-descriptions-item>
          <a-descriptions-item :span="6" v-else label="联系人"></a-descriptions-item>
        </template>
        <a-descriptions-item :span="9" label="订单ID">{{ data.order_no }}</a-descriptions-item>
        <a-descriptions-item :span="9" label="取货电话">{{ outletOrder.pick_up_info.recipient_phone }}</a-descriptions-item>
        <template>
          <a-descriptions-item :span="6" v-if="bookInfo.isAdminOrder" label="下单电话">{{ bookInfo.phone }}</a-descriptions-item>
          <a-descriptions-item :span="6" v-else label="联系电话"></a-descriptions-item>
        </template>
        <a-descriptions-item :span="9" label="下单时间">{{ data.add_time }}</a-descriptions-item>

        <a-descriptions-item :span="9" label="自提时间">{{ getGoodsTime() }}</a-descriptions-item>
        <a-descriptions-item :span="6" label="订单金额">{{ data.order_amount }} 元</a-descriptions-item>
        <a-descriptions-item :span="9" label="支付方式">
          <template v-if="data.order_source >= 20">{{ formatOrderSource(data.order_source) }} - </template>
          <template>{{ getPayTypeShowName() }}</template>
        </a-descriptions-item>
      </template>

      <!-- 顾客备注 -->
      <a-descriptions-item v-if="data.order_remarks" :span="24" label="顾客备注">{{ data.order_remarks }}</a-descriptions-item>
      <!-- 客服备注 -->
      <!-- <a-descriptions-item v-if="data.customer_service_remarks" :span="24" label="客服备注">{{ data.customer_service_remarks }}</a-descriptions-item> -->

    </a-descriptions>

    <!-- 商品 -->
    <base-table class="mt-2"
      :columnsData="goods_columns"
      rowKey="order_goods_id"
      :tableData="tableData"
    ></base-table>

    <!-- 配送二维码 -->
    <a-descriptions bordered size="middle" class="descri-class mt-2" :column="24"
        :style="outletOrderType == 2 ? '' : 'display:none;' ">
      <a-descriptions-item :span="20" label="扫码配送">
        <div class="qrcode mt-2 mb-2 ml-2 mr-2 flex" style="justify-content:space-between; align-items:center;">
          <div id="qrcode_img" ref="qrcode_img"></div>
          <div style="margin-top:40px;">顾客签字: ______________</div>
        </div>
      </a-descriptions-item>
    </a-descriptions>
    <template v-if="outletOrderType == 1">
      <div style="float:right; margin-top:20px;">顾客签字: ______________</div>
    </template>
    
  </template>
  </div>
</template>

<script>
import { 
  getShopOrderDesc,
} from "@/api/shop.js"
import { 
  getAllOrderDetail,
} from "@/api/customer"
import {
  formatBirthdayCandle,
  formatOrderSource,
  payTypeList,
  subCODPayTypeList,
  // subOnAccountPayTypeList
} from "@/utils/type"
import {
  formatDate
} from "@/utils/index"
import QRCode from 'qrcodejs2'
import CONFIG from '@/utils/config'

export default {
  components: { 

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CONFIG,

      order_no: "",
      suborder_no: "",

      data: {
        pick_up_info: {
          pick_up_code: "",
        },
      },
      outletOrder: {
        pick_up_info: {},
        delivery_info: {},
      },
      outletOrderType: '',

      tableData:[],

      contactInfo: null,
      bookInfo: {},
      serverTime: "",
      outletsInfo: {},

      goods_columns: [
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "规格",
          dataIndex: "specification_name",
          align: "center",
          // slots: { customRender: "specification_name" },
          // slotsType: "all",
          // slotsFunc: (val) => { 
          //   if (val.flavor_name && val.specification_name) return val.flavor_name+" - "+val.specification_name;
          //   else return val.flavor_name+""+val.specification_name
          // },
        },
        {
          title: "餐具",
          dataIndex: "cutlery",
          align: "center",
          width: 100,
        },
        {
          title: "蜡烛",
          dataIndex: "birthday_candle",
          align: "center",
        },
        {
          title: "生日祝福",
          dataIndex: "birthday_card",
          align: "center",
        },
        {
          title: "贺卡",
          dataIndex: "birthday_greeting_card",
          align: "center",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: 60,
        },
      ]
    }
  },
  created() {
    this.order_no = this.$route.query.order_no
    this.suborder_no = this.$route.query.suborder_no

    try{
      let info = JSON.parse(localStorage.getItem('outletsInfo'))
      this.outletsInfo = info || {}
    }catch(e){
      console.log(e)
    }
  },
  async mounted() {
    if(this.suborder_no){
      await this.initData()
    }else{
      await this.initDataOrder()
    }
    this.renderQrCode()
  },
  methods: {
    // formatBirthdayCandle,
    formatOrderSource,

    async initData() {
      const { data, code, timestamp } = await getShopOrderDesc({
        suborder_no: this.suborder_no,
      })
      if (code === 0) {
        let outletOrder = data
        let goods_list = outletOrder.goods_list
        let cake_list = outletOrder.cake_accessory_list || []
        cake_list.map(cake=>{
          let item = goods_list.find(el=>el.order_goods_id == cake.order_goods_id)
          if(item){
            Object.assign(item, {
              birthday_candle: formatBirthdayCandle(cake.birthday_candle),
              birthday_card: cake.birthday_card,
              birthday_greeting_card: cake.birthday_greeting_card,
              // diners_number: cake.diners_number,
              cutlery: "餐具"+cake.diners_number + (cake.plastic_tableware_number > 0 ? "、一次性"+cake.plastic_tableware_number : ""),
            })
          }
        })

        this.serverTime = formatDate(timestamp)
        this.data = data
        this.outletOrder = outletOrder
        this.outletOrderType = data.order_type
        this.tableData = goods_list

        if(outletOrder.delivery_info && outletOrder.delivery_info.contacts_phone){
          this.contactInfo = {
            name:  outletOrder.delivery_info.contacts_name,
            phone: outletOrder.delivery_info.contacts_phone
          }
        }
        if(data.place_order_phone){
          this.bookInfo = {
            isAdminOrder: data.order_source ===10 || data.order_source ===15,
            name:  data.place_order_name,
            phone: data.place_order_phone
          }
        }
        
        // this.$nextTick(() => {
        //   window.print()
        // })
      }
    },
    async initDataOrder() {
      const { data, code, timestamp } = await getAllOrderDetail({
        order_no: this.order_no,
      })
      if (code === 0) {
        data.add_time = data.order_add_time

        let outletOrder = data.pick_up_delivery_order
        let goods_list = outletOrder.goods_list
        let cake_list = outletOrder.cake_accessory_list || []
        cake_list.map(cake=>{
          let item = goods_list.find(el=>el.order_goods_id == cake.order_goods_id)
          if(item){
            Object.assign(item, {
              birthday_candle: formatBirthdayCandle(cake.birthday_candle),
              birthday_card: cake.birthday_card,
              birthday_greeting_card: cake.birthday_greeting_card,
              // diners_number: cake.diners_number,
              cutlery: "餐具"+cake.diners_number + (cake.plastic_tableware_number > 0 ? "、一次性"+cake.plastic_tableware_number : ""),
            })
          }
        })

        this.serverTime = formatDate(timestamp)
        this.data = data
        this.outletOrder = outletOrder
        this.outletOrderType = outletOrder.suborder_type
        this.tableData = goods_list

        if(outletOrder.delivery_info && outletOrder.delivery_info.contacts_phone){
          this.contactInfo = {
            name:  outletOrder.delivery_info.contacts_name,
            phone: outletOrder.delivery_info.contacts_phone
          }
        }
        if(data.place_order_phone){
          this.bookInfo = {
            isAdminOrder: data.order_source ===10 || data.order_source ===15,
            name:  data.place_order_name,
            phone: data.place_order_phone
          }
        }
        
        // this.$nextTick(() => {
        //   window.print()
        // })
      }
    },

    renderQrCode(){
      const d = 80
      const qrdiv = document.getElementById('qrcode_img')
      new QRCode(qrdiv, {
        width:  d,  // 二维码宽度 
        height: d, // 二维码高度
        text: this.outletOrder.suborder_no,
        correctLevel: QRCode.CorrectLevel.L
      })
    },
    
    getGoodsTime() {
      let start, end
      if (this.outletOrderType === 2) {
        start = this.outletOrder.delivery_info.delivery_time_start
        end   = this.outletOrder.delivery_info.delivery_time_end
      } else {
        start = this.outletOrder.pick_up_info.pick_up_goods_time_start
        end   = this.outletOrder.pick_up_info.pick_up_goods_time_end
      }
      if(start){
        if(start == end){
          return (
            start.replace(/:00$/, "")
          )
        }else{
          return (
            start.replace(/:00$/, "") + "-" + end.split(" ")[1].replace(/:00$/, "")
          )
        }
      }
      return ""
    },
    getOrderStatus(id) {
      const statusOBj = {
        1: "配送未完成",
        2: "自提未完成",
        3: "已完成",
        4: "已取消",
      }
      return statusOBj[id]
    },
    // 支付方式（给网点展示的略有隐藏）
    getPayTypeShowName(){
      const data = this.data
      if(data.pay_type == 10){ // 货到付款：货到付款(刷卡)
        // test 黑天鹅后台下单也有其他货到付款方式
        // if(data.pay_subtype > 0){
        //   return "货到付款-" + this.getSubPayTypeName(data.pay_subtype)
        // }
        return "货到付款"
      }else if(data.is_cash_delivery == 2){ // 货到付款 转在线支付 但没支付
        return "货到付款"
      }else if((data.order_source == 1 || data.order_source == 2) && data.pay_type == 2){ // 小程序订单（微信）
        return "微信"
      }else if(data.order_source == 3 || data.order_source == 4 || data.order_source == 5 || data.order_source == 6){ // 网站订单
        return "科技公司挂帐"
      }else if(data.pay_type == 30){ // 挂账：第三方平台
        return "科技公司挂帐"
      }else if(data.order_source == 10 && data.pay_type == 20){ // 客服下单-其他支付
        return "科技公司挂帐"
      }else if(data.order_source == 15 && data.pay_type == 20){ // 网点下单-其他支付
        return "挂账（门店）"
      }else{ // x支付（应该走不到这个逻辑）
        return this.getPayTypeName(data.pay_type)
      }

      // return "未知"
    },
    // 支付方式
    getPayTypeName(id) {
      let item = payTypeList.find(el=>{
        if(id == el.id) return el
      })
      return item && item.name || ""
    },
    getSubPayTypeName(id) {
      let item = subCODPayTypeList.find(el=>{
        if(id == el.id) return el
      })
      // let item = subCODPayTypeList.concat(subOnAccountPayTypeList).find(el=>{
      //   if(id == el.id) return el
      // })
      return item && item.name || ""
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>

.print-s{
  font-size: 12px;
  color: #000;
  padding: 4px;
  margin-right: 35px;
}

.t-hd{
  margin: 15px 10px 5px;
  justify-content: space-between;
  align-items: end;
}

.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label, 
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content{
  padding: 2px 0;
  padding: 1px 0 !important;
  text-align: center;
  font-size: 12px;
  min-width: 40px;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label{
  width: 64px;
  background: none;
}

.ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr>th,
.ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-tbody>tr>td{
  padding: 2px 0;
  padding: 1px 0 !important;
  text-align: center;
  font-size: 12px;
  min-width: 40px;
  font-weight: 400;
  background: #fff;
}

.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead>tr:only-child>th:last-child{
  border-right: 1px solid #e8e8e8;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header,
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
  overflow-y: hidden !important;
}

.print-s,
.ant-descriptions-item-label,
.ant-table-thead > tr > th,
.ant-table,
.ant-descriptions-item-content{
  color: #000 !important;
  font-family: none !important;
}

.ant-descriptions-bordered .ant-descriptions-view,
.ant-descriptions-bordered .ant-descriptions-item-label, 
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-row,
.ant-table-bordered .ant-table-header > table, 
.ant-table-bordered .ant-table-body > table, 
.ant-table-bordered .ant-table-fixed-left table, 
.ant-table-bordered .ant-table-fixed-right table,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td{
  border-color: #000 !important;
  border-radius: 0 !important;
}

</style>